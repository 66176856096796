import { httpsCallable } from "firebase/functions";
import { Functions } from "../FirebaseConfig/FirebaseConfig";

// service to validate the card
export const ValidateCard = async (token: string, userId: string) => {
    const validatePaymentMethod = httpsCallable(Functions, 'validatePaymentMethod');
    return validatePaymentMethod({
        token: token,
        userId: userId
    }).then((response) => {
         return response.data;
    }).catch((error) => {
        console.log(error);
        throw error;
    });
}