import React, { useEffect } from "react";

import { CardPayment, initMercadoPago } from '@mercadopago/sdk-react';

import "./MercadoPagoCheckout.css";
import { TypeIdentify } from "../../@types/TypeIdentify";
import { useDispatch, useSelector } from "react-redux";
import { ValidateCard } from "../../Service/ValidateCard";
import { toast } from "react-toastify";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { AppDispatch } from "../../Store/store";
import { GetUserByUID } from "../../Slice/User";

const { 
    REACT_APP_MERCADO_PAGO_KEY,
} = process.env;

interface MercadoPagoCheckoutProps {
    amount: number; // amount to pay
    email: string // email of the user
    typeIt: TypeIdentify; // type of identify
    itNumber: string; // number of identify
    onPayClick: (token: string) => void;
}

export const  MercadoPagoCheckout: React.FC<MercadoPagoCheckoutProps> = ({
    amount,
    email,
    typeIt,
    itNumber,
    onPayClick
}) => {

    const dispatch = useDispatch<AppDispatch>();
    
    const {userDetail} = useSelector((state: any) => state.user);

    const [loading, setLoading] = React.useState<boolean>(true);

    const initialization = {
        amount: amount,
        preferenceId: "",
        payer: {
            email: email,
            identification: {
                type: typeIt,
                number: itNumber
            }
        },
    };
       
    const customization = {
        visual: {
            style: {
                customVariables: {
                    //theme: 'default', // | 'dark' | 'bootstrap' | 'flat'
                    textPrimaryColor: "#ffffff",
                    formBackgroundColor: "none",
                    inputBackgroundColor: "rgba(0, 0, 0, 0.20)",
                    inputVerticalPadding: "12px",
                    inputHorizontalPadding: "12px",
                    borderRadiusSmall: "5px",
                    borderRadiusMedium: "24px",
                    borderRadiusLarge: "24px",
                    borderRadiusFull: "24px",
                    baseColor: "#FF9525",
                    inputBorderWidth: "0px",
                    fontSizeExtraSmall: "12px",
                    fontSizeSmall: "12px",
                    fontSizeMedium: "12px",
                    fontSizeLarge: "16px",
                    fontSizeExtraLarge: "16px",
                    fontFamily: "Poppins-Medium",
                },
            },
            texts: {
                formTitle: "Mercado Pago",
            }
        },
        paymentMethods: {
            minInstallments: 1,
            maxInstallments: 1,
          }   
    };

    const onSubmit = async (data: any) => {
        console.log("mercado pago data", data);
        ValidateCard(data.token, userDetail.id)
        .then((response) => {
           // console.log("🚀 ~ onSubmit ~ response", response);
            onPayClick(data.token);
        }).catch((error) => {
            console.log("🚀 ~ onSubmit ~ error", error);
            toast.error("Tuvimos un problema al validar la tarjeta", {autoClose: 5500});
        });
    };

    const onError = async (error: any) => {
        // callback llamado para todos los casos de error de Brick
        console.log(error);
    };
    
    const onReady = async () => {
    /*
        Callback llamado cuando el Brick está listo.
        Aquí puede ocultar cargamentos de su sitio, por ejemplo.
    */
    };

    const getCurretnAuthUser = async () => {
        return new Promise((resolve, reject) => {
            try {
                const auth = getAuth();
                onAuthStateChanged(auth, (userCurrent) => {
                    resolve(userCurrent);     
                });
            } catch (error) {
                console.log("returnnewPromise ~ error:", error);
                reject(error);
            }
        });
    }
    
    // redux get user by uid
    const getUserById = (uid: string) => {
        setLoading(true);
        dispatch(GetUserByUID(uid))
            .unwrap()
            .then(async(response) => {
                setLoading(false);
            }).catch((error)=> {
                console.log("🚀 ~ file: AdminPage.tsx:232 ~ getUserById ~ error:", error);
                setLoading(false);
            })
    }
    
    useEffect(() => {
        getCurretnAuthUser()
            .then((userCurrent: any) => {
                setLoading(true);
                if(userCurrent) {
                    // register notification
                    getUserById(userCurrent.uid);
                }
            }).catch((error) => {
                    console.log("🚀 ~ useEffect ~ error", error);
                    setLoading(false);
            });
        initMercadoPago(REACT_APP_MERCADO_PAGO_KEY || "");
    }, []);

    return (
        <div className="mercadoPagoCheckout-container"> 
            {!loading && <CardPayment
                initialization={initialization}
                onSubmit={onSubmit}
                onReady={onReady}
                onError={onError}
                customization={customization}
                locale="es-PE"
            />}
        </div>
    );
}