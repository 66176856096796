import React, { useEffect, useRef, useState } from "react";

import './Register.css';
import { RegisterSocial } from "../RegisterSocial/RegisterSocial";
import { RegisterFull } from "../RegisterFull/RegisterFull";
import { RegisterCode } from "../RegisterCode/RegisterCode";
import { GoBack } from "../GoBack/GoBack";
import { RegisterPay } from "../RegisterPay/RegisterPay";
import { RegisterMail } from "../RegisterGmail/RegisterMail";
import { AuthSteps, AuthSteps_item } from "../../Const/AuthConst";
import { CardBackground } from "../CardBackground/CardBackground";
import { Loader } from "../Loader/Loader";
import { UserInterface } from "../../Interfaces/UserInterface";
import { toast } from "react-toastify";
import { PlanInterface } from "../../Interfaces/PlansInterface";
import AuthService from "../../Service/Auth.service";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../Store/store";
import { RegisterWithEmail, setAuth } from "../../Slice/Auth";
import { CreateUser, GetUserByEmail, GetUserById } from "../../Service/User.service";
import { useNavigate } from "react-router-dom";
import { HOME, STORAGE_ASSETS } from "../../Const/BaseUrlConst";
import { GetValidDate } from "../../Utils/GetValidDate";
import { TypeIdentify } from "../../@types/TypeIdentify";

interface RegisterProps { 
    initialStep?: AuthSteps_item;
    closeModal: () => void;
    planRegister: PlanInterface;
}

export const Register: React.FC<RegisterProps> = ({
    initialStep = "MAIN_REGISTER_STEP",
    closeModal,
    planRegister,
}) => {

    // useState Loading
    const [ loading, setLoading ] = useState(false);

    const navigation = useNavigate();

    const dispatch = useDispatch<AppDispatch>();

    const { uid, userId } = useSelector((state: any) => state.auth);


    const ref = useRef(null);
    // state to manage authStep  
    const [registerStep, setRegisterStep] = useState<AuthSteps_item>("MAIN_REGISTER_STEP");

    const [newUser, setNewUser] = useState<UserInterface>({
        uid: "",
        authProvider: "email",
        providerToken: "",
        subscriptionId: "", 
        name: "",
        fathersLastName: "",
        mothersLastName: "",
        birthday: "",
        phone: 0,
        phoneCode: "",
        phoneFlag: "",
        gender: "",
        country: {
            id: "",
            name: "",
            // city: "",
            imgFlag: "",
            alpha2Code: "",
            // region: "",
        },
        city: "",
        direction: "",
        addressReference: "",
        typeIt: "",
        itNumber: 0,
        email: "",
        codeUnit: "",
        mpClientId: "",
        zodiacSignId: "",
        zodiacSignName: "",
        createdAt: new Date(),
        updatedAt: new Date(),
        registerPhase: 1,
        planId: "",
        imgProfile: "",
        status: true,
    });

    const [password, setPassword] = useState<string>("");

    const [emailCode, setEmailCode] = useState<string>("");

    // method to get title step
    const getTitleStep = () => {
        return AuthSteps.find( a => a.step === registerStep)?.title
    }

    // method to get step item
    const getStepItem = () => {
        return AuthSteps.find( a => a.step === registerStep)?.registerStep
    }

    // handle go back click
    const handleGoBack = () => {
       switch (registerStep) {
        case "CHECKOUT_STEP":
            //setRegisterStep("REGISTER_DETAIL_STEP");
            toast.error("No puedes retroceder en este paso");
            break;
        case "REGISTER_DETAIL_STEP":
            setRegisterStep("REGISTER_EMAIL_STEP");
            break;
        case "REGISTER_CODE_STEP":
            setRegisterStep("REGISTER_EMAIL_STEP");
            break;
        case "REGISTER_EMAIL_STEP":
            setRegisterStep("MAIN_REGISTER_STEP");
            break;
        case "MAIN_REGISTER_STEP":
            closeModal();
            break;
        default:
            break;
       }
    }

    // handle register method 
    const handleRegisterMethod = (method: "email" | "google" | "facebook") => {
        setNewUser((prev) => ({...prev, authProvider: method}));
     
        if(method === "email") { 
            setRegisterStep("REGISTER_EMAIL_STEP");
        } else if(method === "google") {
            handleRegisterWithGoogle();
        }
    }

    const handleRegisterWithGoogle = async () => {
        if(!loading) {
            setLoading(true);
            const toastGogle = toast.loading('Solicitando las credenciales a Google...');
            try {
                const response:any = await AuthService.RegisterWithGoogle();
                if(response) {
                    const isCreated = await GetUserByEmail(response.email);
                    if(!isCreated) {
                        toast.update(toastGogle, 
                            {
                                render: 'Credenciales recibidas', 
                                type: 'success', 
                                isLoading: false,
                                autoClose: 2000,
                                closeButton: true,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false
                            }
                        );
                        let splitDisplayName = response.displayName.split(' ');
                        setNewUser((prev) => ({
                            ...prev,
                            name: splitDisplayName[0],
                            fathersLastName: splitDisplayName[1],
                            email: response.email,
                            uid: response.uid,
                            authProvider: "google",
                            providerToken: response.accessToken,
                            registerPhase: 2,
                            imgProfile: response.photoURL || "",
                        }));
           
                        setLoading(false);
                        setRegisterStep("REGISTER_DETAIL_STEP");
                    
                    } else {
                        toast.update(toastGogle, 
                            {
                                render: '¡Ya existe un usuario con esta cuenta!, estamos iniciando sesión con ella', 
                                type: 'info', 
                                isLoading: false,
                                autoClose: 5000,
                                closeButton: true,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false
                            }
                        );
                        const responseUser = await GetUserByEmail(response.email);
                        dispatch(setAuth({
                            isLoggedIn: true,
                            displayName: responseUser.name+" "+responseUser.fathersLastName,
                            userId: responseUser.id,
                            uid: responseUser.uid,
                            imgProfile: responseUser.imgProfile || "",
                        }));
                        setLoading(false);
                        setTimeout(() => {
                            redirectToHome();
                        }, 500);
                    }
                }
            } catch (error) {
                console.log("🚀 ~ handleRegisterWithGoogle ~ error:", error)
                toast.error("Error al registrar con Google");
                setLoading(false);
            }
        }
    }

    // mathod to redirect to home
    const redirectToHome = () => {
        closeModal();
        navigation(`/${HOME}`);
    }

    // handle validation email and password and send email code to user
    const handleValidationEmail = async() => {
        if(loading) return;
        
        setLoading(true);
        try {
            const response: any = await AuthService.CheckEmailRegister(newUser.email.toLocaleLowerCase().trim());
            if(!response) {
                const respCode: any = await AuthService.sendCodeVerification("email", newUser.email);  
                if(respCode) {
                    toast.success("Código de verificación enviado, revise su correo");
                    setRegisterStep("REGISTER_CODE_STEP");
                    setLoading(false);
                } else {
                    toast.error("Error al enviar el código de verificación 2GSZ");
                }
                setLoading(false);
            } else {
                toast.warning("El correo ya está registrado, Inicia sesión o intenta con otro correo");
                setLoading(false);
            }
        } catch (error) {
            console.log("🚀 ~ handleRegisterMethod ~ error:", error);
            toast.error("Error al enviar el código de verificación 1GSZ");
        }
    }


    // method to validate code and disable code
    const handleValidateCode = async (type: "email" | "phone", valueType: string, code: string) => {
        setLoading(true);
        try {
            const response = await AuthService.ValidateRegisterCode(type, valueType, code);
            if(response) {
                const disableCodes = await AuthService.DisableCodeRegister(type, valueType, code);
                setLoading(false);
                if(disableCodes) {
                    return true
                } else {
                    return false
                }
            } else {
                setLoading(false);
                toast.warning("Código incorrecto, intentelo otra vez");
                return false;
            }           
        } catch (error) {
           console.log("🚀 ~ error:", error);
            toast.error("Hubo un error al validar el código, intentelo en unos minutos");
            setLoading(false);
            return false;
        }
    }

    // method to handle verify code
    const handleVerify = async () => {
        const validation = await handleValidateCode("email", newUser.email, emailCode);
        if(validation) {
            setNewUser((prev) => ({...prev, registerPhase: 2}));
            setRegisterStep("REGISTER_DETAIL_STEP");
         }
    }

    const registerWIthEmail = async () => {
        dispatch(RegisterWithEmail({user: {...newUser, registerPhase: 3}, password: password}))
        .unwrap()
        .then((response) => {
            if(response) {
                setNewUser((prev) => ({...prev, id: response.userId, registerPhase: 3}));
                setRegisterStep("CHECKOUT_STEP");
            }
            setLoading(false);
        }).catch((error) => {
            console.log("🚀 ~ .then ~ error:", error);
            setLoading(false);
            toast.error("Error al registrar el usuario");
        })
    }

    const registerWithGoogle = async () => {
        try {
          
            const responseUser = await CreateUser({...newUser, registerPhase: 3, createdAt: new Date(), updatedAt: new Date()});
            console.log("🚀 ~ registerWithGoogle ~ responseUser:", responseUser)
            if(responseUser) {
                dispatch(setAuth({
                    isLoggedIn: true,
                    displayName: newUser.name+" "+newUser.fathersLastName,
                    userId: responseUser.id,
                    uid: newUser.uid,
                    imgProfile: newUser.imgProfile || "",
                }));
                setRegisterStep("CHECKOUT_STEP");
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log("🚀 ~ registerWithGoogle ~ error:", error)
            toast.error("Error al registrar el usuario, intentelo de nuevo");
        }
    }

    // method to handle full register
    const handleFullRegister = () => {
        if(newUser.authProvider === "email") {
            registerWIthEmail();
        } else if(newUser.authProvider === "google") {
            registerWithGoogle();
        }
    }

    // handle change birthda
    const handleNewUserChange = (value: string, key: string) => {
        setNewUser((prev) => ({...prev, [key]: key === "birthday" ? GetValidDate(value) : value}));
    }

    // handle change country
    const handleChangeCountry = (name: string,imgFlag: string, alpha2Code: string) => {
        setNewUser((prev) => ({...prev, country: {name, imgFlag, alpha2Code}}));
    }

    // handle change phone, phoneCode, phoneFlag
    const handlePhoneChange = (value: string, field: "phone" | "phoneCode" | "phoneFlag") => {
        setNewUser((prev) => ({...prev, [field]: value}));
    }

    // method to get user by id
    const getUserById = async (uid: string) => {
        if(loading) return;
   
        try {
            setLoading(true);
            const response: any = await GetUserById(uid);
            console.log("🚀 ~ getUserById ~ response:", response);
            setNewUser(response);
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getUserById ~ error:", error);
            setLoading(false);
        }
    }

    const updateUserAndGoToHome = () => { 
        dispatch(setAuth({
            isLoggedIn: true,
            displayName: newUser.name+" "+newUser.fathersLastName,
            userId: newUser.id,
            uid: newUser.uid,
            imgProfile: "",
        }));
        closeModal();
        navigation("/"+HOME);
    }

    useEffect(() => {
        if(initialStep !== "MAIN_REGISTER_STEP" && userId && userId.length>0) {
            setRegisterStep(initialStep);
            getUserById(userId);
        } 

        setNewUser((prev: any) => ({
            ...prev,
            planId: planRegister.id
        }));
    }, []);

    return (
        <div className="register" ref={ref} >
            <CardBackground>
                <div className="register-container">
                    <GoBack handleClick={()=>handleGoBack()}/>
                    <div className="register-header">
                        <div className="medium-header register-text">Pon a prueba tu suerte</div>
                        <div className="register-header-subtitle">
                            <div className="tinytext register-text register-text-subtitle">{getTitleStep()}</div>
                            <div className="tinytext-header register-text-orange">PASO {getStepItem()} DE 3</div>
                        </div>
                    </div>
                    {!loading ?
                    <div className="register-body">
                        {
                            registerStep === "MAIN_REGISTER_STEP" ?
                            <RegisterSocial
                                handleClick={handleRegisterMethod}
                            /> : 
                            registerStep === "REGISTER_EMAIL_STEP" ?
                            <RegisterMail
                                email={newUser.email}
                                emailChange={(e)=>handleNewUserChange(e.target.value, "email")}
                                password={password}
                                passwordChange={(e)=>setPassword(e.target.value)}
                                handleClick={handleValidationEmail}
                            /> : 
                            registerStep === "REGISTER_CODE_STEP" ?
                            <RegisterCode
                                code={emailCode}
                                setCode={setEmailCode}
                                handleClick={handleVerify}
                            /> :
                            registerStep === "REGISTER_DETAIL_STEP" ?
                            <RegisterFull
                                newUser={newUser}
                                handleInputchange={handleNewUserChange}
                                handleChangeCountry={handleChangeCountry}
                                handlePhoneChange={handlePhoneChange}
                                handleClick={handleFullRegister}
                                loading={loading}
                                setLoading={setLoading}
                            /> :
                            <RegisterPay
                                planRegister={planRegister}
                                email={newUser.email}
                                updateUserAndGoToHome={updateUserAndGoToHome}
                                userId={userId || newUser.id}
                                userTypeIt={newUser.typeIt as TypeIdentify}
                                userItNumber={newUser.itNumber.toString()}
                            />
                        }
                    </div> :
                    <div className="register-loader"><Loader/></div>}
                </div>
            </CardBackground>
        </div>
    )
}